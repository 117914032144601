import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getDetailOrder } from "../../../store/features/orderSlice";
import { TfiControlBackward } from "react-icons/tfi";
import { StatusButton } from "../../../components";
import { urlConfig } from "../../../config";

const DetailsOrder = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const detailsOrder =
    useSelector((state) => state.order.detailsOrderData) || {};
  const updateStatusLoading = useSelector(
    (state) => state.order.updateStatusLoading
  );
  const { user, items } = detailsOrder;
  console.log(detailsOrder);
  useEffect(() => {
    dispatch(getDetailOrder(id));
  }, [dispatch, id, updateStatusLoading]);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div className="flex justify-between items-center border py-5 px-5 rounded-lg">
        <div className="flex space-x-2 ">
          <Link
            className="w-[50px] h-[50px] border rounded-lg flex items-center justify-center"
            to="/order"
          >
            <TfiControlBackward />
          </Link>
          <div className="flex flex-col">
            <span>Porosia</span>#{detailsOrder.id}
          </div>
        </div>

        <div className="flex flex-col items-center">
          <h1>Statusi</h1>
          <StatusButton order={detailsOrder} />
        </div>
      </div>
      <div className="flex justify-between ">
        <div className="mt-10 border py-5 px-5 rounded-lg space-y-2 w-full">
          <h3 className="text-lg font-semibold">Produktet e porositura</h3>
          <table className="min-w-full bg-white">
            <thead className="bg-gray-200">
              <tr>
                <th className="py-2 px-4">Foto</th>
                <th className="py-2 px-4">Emri i produktit</th>
                <th className="py-2 px-4">SKU</th>
                <th className="py-2 px-4">Sasia</th>
                <th className="py-2 px-4">Cmimi</th>
                <th className="py-2 px-4">Subtotal</th>
              </tr>
            </thead>
            <tbody>
              {items?.map((item) => (
                <tr key={item.id} className="border-t">
                  <td className="py-2 px-4">
                    <img
                      src={`${urlConfig}/uploads/product/main/${
                        JSON.parse(item.product.path)[0].path
                      }`}
                      alt={item.product.name}
                      className="w-20 h-20 object-contain"
                    />
                  </td>
                  <td className="py-2 px-4">
                    {item.product.name.slice(0, 25)} ...
                  </td>
                  <td className="py-2 px-4">{item.product.SKU}</td>
                  <td className="py-2 px-4 text-center">{item.quantity}</td>
                  <td className="py-2 px-4 text-center flex flex-col items-center">
                    {item.product.discount ? (
                      <>
                        <span className="line-through">
                          {item.product.price} €
                        </span>
                        <span>{item.product.discount} €</span>
                      </>
                    ) : (
                      <span>{item.product.price} €</span>
                    )}
                  </td>

                  <td className="py-2 px-4 text-center">
                    {item.quantity *
                      (item.product.discount
                        ? item.product.discount
                        : item.product.price)}{" "}
                    €
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex justify-between px-5">
            <div></div>
            <div className="flex flex-col items-center space-y-1">
              <span>Totali</span>
              <span className="border px-5 py-1 rounded-full">
                {" "}
                {detailsOrder?.total_price} €{" "}
              </span>
            </div>
          </div>
        </div>
        <div className="mt-10 border py-5 px-5 rounded-lg space-y-2">
          <p className="flex justify-between">
            <strong className="w-[50%]">ID:</strong> {user?.id}
          </p>
          <p className="flex justify-between">
            <strong className="w-[50%]">Emri:</strong> {user?.name}{" "}
            {user?.surname}
          </p>
          <p className="flex justify-between">
            <strong className="w-[50%]">Mbiemri:</strong> {user?.email}
          </p>
          <p className="flex justify-between">
            <strong className="w-[50%]">Telefoni:</strong> {user?.phone}
          </p>
          <p className="flex justify-between">
            <strong className="w-[50%]">Adresa:</strong> {user?.address}
          </p>
          <p className="flex justify-between">
            <strong className="w-[50%]">Emri i kompanis:</strong>{" "}
            {user?.company_name}
          </p>
          <p className="flex justify-between">
            <strong className="w-[50%]">Numri i kompanis:</strong>{" "}
            {user?.company_number}
          </p>
          <p className="flex justify-between">
            <strong className="w-[50%]">Tipi:</strong> {user?.type}
          </p>
          <p className="flex justify-between">
            <strong className="w-[50%]">Data e porosis:</strong>{" "}
            {new Date(user?.created_At).toLocaleString()}
          </p>
        </div>
      </div>

      <div className="flex flex-col mt-10  border py-5 px-5 rounded-lg">
        <h1>Mesazhi</h1>
        <p>{detailsOrder?.message}</p>
      </div>
    </div>
  );
};

export default DetailsOrder;

import AppRoutes from "./route";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'chart.js/auto';
const App = () => {
  return (
    <>
      <ToastContainer />
      <AppRoutes />
    </>
  )
}

export default App;

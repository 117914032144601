// ImageCarousel.js
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { urlConfig } from "../../config";

function ImageCarousel({ images }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Ensure only one slide is shown at a time
    slidesToScroll: 1, // Ensure only one slide is scrolled at a time
  };

  return (
    <div className="w-[400px]">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img
              src={`${urlConfig}/uploads/product/main/${image.path.replace(
                /\\\\/g,
                "/"
              )}`}
              alt={`Slide ${index}`}
              className="w-full h-auto"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default ImageCarousel;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";


export const getSpecification = createAsyncThunk('details/getSpecification', async ({ page = 1, limit = 25, searchTerm = '' }) => {
    try {
        const response = await axiosInstance.get(`/specification/getSpecification?page=${page}&limit=${limit}&search=${searchTerm}`);

        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})


export const deleteSpecification = createAsyncThunk('details/deleteSpecification', async (id) => {
    try {
       const response =  await axiosInstance.delete(`/specification/deleteSpecification/${id}`);
       return response.data;
    } catch (error) {
        throw new Error(error.response.data.message); 
    }
})

export const createSpecification = createAsyncThunk('details/createSpecification', async (data) => {
    try {
        const response = await axiosInstance.post('/specification/createSpecification', data);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message); 
    }
})

export const showDetailsFilter = createAsyncThunk('details/showDetailsFiler', async ({ id, value}) => {
    try {
        const response = await axiosInstance.post(`/specification/is_show_in_filter/${id}`, value);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})

const initialState = {
    showDetailsFilterData: null,
    showDetailsFilterLoading: false,
    showDetailsFilterError: null,
    createSpecificationData: null,
    createSpecificationLoading: false,
    createSpecificationError: null,
    specificationData: null,
    specificationLoading: false,
    specificationError: null,
    deleteSpecificationData: null,
    deleteSpecificationLoading: false,
    deleteSpecificationError: null
}

const detailsSlice = createSlice({
    name: "details",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(showDetailsFilter.rejected, (state, action) => {
                state.showDetailsFilterError = action.error.message;
                state.showDetailsFilterLoading = false;
            })
            .addCase(showDetailsFilter.fulfilled, (state, action) => {
                state.showDetailsFilterData = action.payload;
                state.showDetailsFilterLoading = false;
            })
            .addCase(showDetailsFilter.pending, (state) => {
                state.showDetailsFilterError = null;
                state.showDetailsFilterLoading = true;
            })
            .addCase(createSpecification.rejected, (state, action) => {
                state.createSpecificationError = action.error.message;
                state.createSpecificationLoading = false;
            })
            .addCase(createSpecification.fulfilled, (state, action) => {
                state.createSpecificationData = action.payload;
                state.createSpecificationLoading = false;
            })
            .addCase(createSpecification.pending, (state) => {
                state.createSpecificationError = null;
                state.createSpecificationLoading = true;
            })
            .addCase(getSpecification.rejected, (state, action) => {
                state.specificationError = action.error.message;
                state.specificationLoading = false;
            })
            .addCase(getSpecification.fulfilled, (state, action) => {
                state.specificationData = action.payload;
                state.specificationLoading = false;
            })
            .addCase(getSpecification.pending, (state) => {
                state.specificationLoading = true;
                state.specificationError = null;
            })
            .addCase(deleteSpecification.rejected, (state, action) => {
                state.deleteSpecificationError = action.error.message;
                state.deleteSpecificationLoading = false;
            })
            .addCase(deleteSpecification.fulfilled, (state, action) => {
                state.deleteSpecificationData = action.payload;
                state.deleteSpecificationLoading = false;
            })
            .addCase(deleteSpecification.pending, (state) => {
                state.deleteSpecificationLoading = true;
                state.deleteSpecificationError = null;
            })
    }
})

export default detailsSlice.reducer;
import { IoIosCloseCircle } from "react-icons/io";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {  getSpecification } from "../../../store/features/detailsSlice";
import { createSpecification } from "../../../store/features/productSlice";

const CreateSpecificationDetails = ({ openModal, productId }) => {
  const dispatch = useDispatch();
  const specification = useSelector((state) => state.details.specificationData) || [];
  const [formData, setFormData] = useState({
      name: '',
      specId: ''
  });

  const onSubmit = (e) => {
      e.preventDefault();
      const data = {
          productId,
          value: formData.name,
          specificationId: formData.specId
      }

      dispatch(createSpecification(data)).unwrap()
          .then(() => {
              toast.success("Detaji u shtua me sukses")
          })
          .catch(() => toast.error("Diqka shkoi keq!"))
      setFormData({
          name: "",
          categoryId: ""
      })

  }

  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
          ...formData,
          [name]: value,
      });
  };

  useEffect(() => {
      dispatch(getSpecification());
  }, [dispatch]);

  return (
      <div className="fixed inset-0 z-50 flex items-center justify-center w-15">
          <div className="bg-gray-900 bg-opacity-40 absolute inset-0"></div>
          <div
              className="relative bg-white rounded-lg shadow-lg"
              style={{ width: "420px" }}
          >
              <div className="p-6">
                  <div className="flex items-center justify-between">
                      <h3 className="mb-1 text-xl font-normal text-center text-gray-500 dark:text-gray-400">
                          Shto nënkategorinë
                      </h3>
                      <button onClick={openModal}>
                          <IoIosCloseCircle />
                      </button>
                  </div>
                  <form autoComplete="off" onSubmit={onSubmit}>
                      <div>
                          <label
                              htmlFor="name"
                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                              Emri
                          </label>
                          <input
                              type="text"
                              name="name"
                              id="name"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                              placeholder="Name"
                              value={formData.name}
                              onChange={handleChange}
                          />
                      </div>
                      <div className="mt-4">
                          <label
                              htmlFor="specId"
                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                              Fusha specifike
                          </label>
                          <select
                              name="specId"
                              id="specId"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                              value={formData.specId}
                              onChange={handleChange}
                          >
                              <option value="">Zgjidh kategorinë</option>
                              {specification.map(spec => (
                                  <option key={spec.specification_id} value={spec.specification_id}>{spec.specification_name} ({spec.category_name})</option>
                              ))}
                          </select>
                      </div>

                      <div className="flex items-center justify-center mt-5">
                          <button
                              type="submit"
                              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
                          >
                              Shto Detajet
                          </button>
                      </div>
                  </form>
              </div>
          </div>
      </div>
  )
}

export default CreateSpecificationDetails

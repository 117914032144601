import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllProduct } from "../../../store/features/productSlice";
import { useState } from "react";
import { getAllUser } from "../../../store/features/userSlice";
import Select from "react-select";
import { createOrderByAdmin } from "../../../store/features/orderSlice";
import { toast } from "react-toastify";
import { urlConfig } from "../../../config";

const AddOrder = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user.allUserData) || [];
  const userOptions = users?.users?.map((user) => ({
    value: user.id,
    label: user.name,
  }));

  const productData = useSelector((state) => state.product.productData) || [];
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [userId, setUserId] = useState(null);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      product_id: selectedProduct.id,
      quantity,
      totalPrice,
      userId,
    };
    dispatch(createOrderByAdmin(data))
      .unwrap()
      .then(() => {
        toast.success("Porosia u shtua");
        setUserId(null);
        setQuantity(1);
        setTotalPrice(0);
        setSelectedProduct(null);
      })
      .catch(() => toast.error("Diqka shkoi keq!"));
  };

  const filteredProducts = productData.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSetProduct = (item) => {
    setSelectedProduct(item);
    setTotalPrice(item.price * quantity);
    setSearchTerm("");
  };

  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value, 10);
    setQuantity(newQuantity);
    if (selectedProduct) {
      setTotalPrice(selectedProduct.price * newQuantity);
    }
  };

  const handleChange = (selectedOption) => {
    setUserId(selectedOption.value);
  };
  useEffect(() => {
    dispatch(getAllProduct());
    dispatch(getAllUser({ all: true }));
  }, []);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div>
        <div>
          <div className="p-6">
            <div className="flex items-center justify-between">
              <h3 className="mb-1 text-xl font-normal text-center text-gray-500 dark:text-gray-400">
                Shto Produktin
              </h3>
            </div>

            <div>
              <label
                htmlFor="searchproduct"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white mt-10"
              >
                Kerko Produktin
              </label>
              <input
                type="text"
                name="searchproduct"
                id="searchproduct"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder="Kerko produktin"
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>

            {searchTerm && (
              <div className="mt-5">
                <h4 className="mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Rezultatet e kërkimit
                </h4>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {filteredProducts.map((product) => {
                    const photoData = JSON.parse(product.path)[0];
                    const photoPath = photoData ? photoData.path : "";
                    const iconUrl = `${urlConfig}/uploads/product/main/${photoPath}`;
                    return (
                      <div
                        key={product.id}
                        className="bg-white shadow-md rounded-lg p-4 border border-gray-200 dark:bg-gray-800 dark:border-gray-600"
                      >
                        {photoPath && (
                          <img
                            src={iconUrl}
                            alt={product.name}
                            className="w-[300px] h-48 object-cover rounded-md mb-4"
                          />
                        )}
                        <h5 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                          {product.name}
                        </h5>
                        <div className="flex justify-between">
                          <p className="text-gray-700 dark:text-gray-300">
                            Çmimi:
                          </p>
                          <p className="font-bold text-gray-700 dark:text-gray-300">
                            {product.price} €
                          </p>
                        </div>
                        <div className="flex justify-between">
                          <p className="text-gray-700 dark:text-gray-300">
                            Barkodi:
                          </p>
                          <p className="font-bold text-gray-700 dark:text-gray-300">
                            {product.barcode}
                          </p>
                        </div>
                        <div className="flex justify-between">
                          <p className="text-gray-700 dark:text-gray-300">
                            Zbritje:
                          </p>
                          <p className="font-bold text-gray-700 dark:text-gray-300">
                            {product.discount} €
                          </p>
                        </div>
                        <button
                          onClick={() => {
                            handleSetProduct(product);
                          }}
                          className="mt-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
                        >
                          Zgjidh Produktin
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            <div className="w-full   mt-10">
              {selectedProduct && (
                <div className="p-4 bg-gray-100 dark:bg-gray-800 rounded-md shadow-md">
                  <h3 className="mb-4 text-lg font-semibold text-gray-800 dark:text-white">
                    Produkti i Zgjedhur
                  </h3>
                  <div className="mb-4">
                    {JSON.parse(selectedProduct.path).map(
                      (photoData, index) => {
                        return (
                          <img
                            key={index}
                            src={`${urlConfig}/uploads/product/main/${photoData.path}`}
                            alt={selectedProduct.name}
                            className="w-full h-40 w-[300px] object-contain rounded-md mb-2"
                          />
                        );
                      }
                    )}
                    <h5 className="text-md font-semibold text-gray-900 dark:text-white mb-2">
                      {selectedProduct.name}
                    </h5>
                    <div className="flex justify-between mb-2">
                      <p className="font-bold text-gray-700 dark:text-gray-300">
                        Çmimi:
                      </p>
                      <p className="text-gray-700 dark:text-gray-300">
                        {selectedProduct.price} €
                      </p>
                    </div>
                    <div className="flex justify-between mb-2">
                      <p className="font-bold text-gray-700 dark:text-gray-300">
                        Barkodi:
                      </p>
                      <p className="text-gray-700 dark:text-gray-300">
                        {selectedProduct.barcode}
                      </p>
                    </div>
                    <div className="flex justify-between mb-2">
                      <p className="font-bold text-gray-700 dark:text-gray-300">
                        Zbritje:
                      </p>
                      <p className="text-gray-700 dark:text-gray-300">
                        {selectedProduct.discount} €
                      </p>
                    </div>
                  </div>
                  <form autoComplete="off" onSubmit={onSubmit}>
                    <label
                      htmlFor="quantity"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Sasia e produktit
                    </label>
                    <input
                      type="number"
                      name="quantity"
                      id="quantity"
                      className="bg-gray-200 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 mb-4"
                      placeholder="Vendos sasinë"
                      value={quantity}
                      onChange={handleQuantityChange}
                      min="1"
                      required
                    />

                    <Select
                      options={userOptions}
                      onChange={handleChange}
                      placeholder="Zgjedh klientin"
                      isSearchable
                    />
                    <div className="flex justify-between mb-4">
                      <p className="font-bold text-gray-700 dark:text-gray-300">
                        Çmimi total:
                      </p>
                      <p className="text-gray-700 dark:text-gray-300">
                        {totalPrice} €
                      </p>
                    </div>

                    <button
                      type="submit"
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue active:bg-blue-800 w-full"
                    >
                      Krijo porosin
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOrder;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";

export const getCategory = createAsyncThunk(
  "category/getCategory",
  async ({ page = 1, limit = 25, searchTerm = "", all = false }) => {
    try {
      const allParam = all ? `&all=true` : "";
      const response = await axiosInstance.get(
        `/category/getCategory?page=${page}&limit=${limit}&search=${searchTerm}${allParam}`
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getAllCategory = createAsyncThunk(
  "category/getAllCategory",
  async () => {
    try {
      const response = await axiosInstance.get("/category/getAllCategory");
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const createCategory = createAsyncThunk(
  "category/createCategory",
  async (data) => {
    try {
      const response = await axiosInstance.post(
        "/category/createCategory",
        data
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "category/deleteCategory",
  async (id) => {
    try {
      const response = await axiosInstance.delete(`/category/delete/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
const initialState = {
  categoryData: null,
  categoryLoading: false,
  categoryError: null,
  createCategoryData: null,
  createCategoryLoading: false,
  createCategoryError: null,
  deleteCategoryData: null,
  deleteCategoryLoading: false,
  deleteCategoryError: null,
  getAllCategoryData: null,
  getAllCategoryLoading: false,
  getAllCategoryError: null,
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCategory.rejected, (state, action) => {
        state.getAllCategoryLoading = false;
        state.getAllCategoryError = action.error.message;
      })
      .addCase(getAllCategory.fulfilled, (state, action) => {
        state.getAllCategoryData = action.payload;
        state.getAllCategoryLoading = false;
      })
      .addCase(getAllCategory.pending, (state) => {
        state.getAllCategoryLoading = true;
        state.getAllCategoryError = null;
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.deleteCategoryError = action.error.message;
        state.deleteCategoryLoading = false;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.deleteCategoryData = action.payload;
        state.deleteCategoryLoading = false;
      })
      .addCase(deleteCategory.pending, (state) => {
        state.deleteCategoryLoading = true;
        state.deleteCategoryError = null;
      })
      .addCase(getCategory.rejected, (state, action) => {
        state.categoryError = action.error.message;
        state.categoryLoading = false;
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.categoryData = action.payload;
        state.categoryLoading = false;
      })
      .addCase(getCategory.pending, (state) => {
        state.categoryLoading = true;
        state.categoryError = null;
      })
      .addCase(createCategory.rejected, (state, action) => {
        state.createCategoryError = action.error.message;
        state.createCategoryLoading = false;
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.createCategoryData = action.payload;
        state.createCategoryLoading = false;
      })
      .addCase(createCategory.pending, (state) => {
        state.createCategoryLoading = true;
        state.createCategoryError = null;
      });
  },
});

export default categorySlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";

export const getAllProduct = createAsyncThunk(
    'product/getProduct',
    async ({ page = 1, limit = 25, searchTerm = '' }) => {
      try {
        const response = await axiosInstance.get(
          `/product/getProduct?page=${page}&limit=${limit}&search=${searchTerm}`
        );
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.message);
      }
    }
  );
export const getProductByCategory = createAsyncThunk('product/getProductByCategory', async ({ slug, ...params }) => {
    try {
        const response = await axiosInstance.get(`/product/getProductByCategory/${slug}`, { params });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || error.message);
    }
});
export const getAllProductsByCategoryId = createAsyncThunk('product/getAllProductsByCategoryId', async ({ slug, ...params }) => {
    try {
        const response = await axiosInstance.get(`/product/getAllProductsByCategoryId/${slug}`, { params });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || error.message);
    }
});

export const createProduct = createAsyncThunk('product/createProduct', async (data) => {
    try {
        const response = await axiosInstance.post('/product/createProduct', data);
        return response.data;
    } catch (error) {
        
       throw JSON.stringify(error.response.data)
    }
});

export const deleteProduct = createAsyncThunk('product/deleteProduct', async (id) => {
    try {
        const response = await axiosInstance.delete(`/product/delete/${id}`);
        return response.data
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})

export const getProductById = createAsyncThunk('product/getProductById', async (id) => {
    try {
        const response = await axiosInstance.get(`/product/getProduct/${id}`);
        return response.data
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})

export const deletePhotoFromProduct = createAsyncThunk('product/deletePhotoFromProduct', async ({ id, idPhoto }) => {
    try {
        const response = await axiosInstance.delete(`/product/deletePhotoProduct/${id}/${idPhoto}`);
        return response.data
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})

export const updateProduct = createAsyncThunk('product/updateProduct', async ({ id, formData }) => {
    try {
        const response = await axiosInstance.put(`/product/update/${id}`, formData);
        return response.data
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})

export const setDealsOfTheWeek = createAsyncThunk('product/setDealsOfTheWeek', async ({ id, value }) => {
    try {
        const response = await axiosInstance.put(`/product/setDealsOfTheWeek/${id}`, value);
        return response.data
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})

export const deleteSpecificationById = createAsyncThunk('product/deleteSpecification', async (id) => {
    try {
        const response = await axiosInstance.delete(`/product/deleteSpecificationProduct/${id}`);
        return response.data
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})

export const createSpecification = createAsyncThunk('product/createSpecification', async (data) => {
    try {
        const response = await axiosInstance.post('/product/createSpecificationProduct', data);
        return response.data
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})

const initialState = {
    createSpecificationData: null,
    getProductByCategoryData: null,
    getAllProductsByCategoryIdData:null,
    createSpecificationLoading: false,
    createSpecificationError: null,
    dealsData: null,
    dealsLoading: false,
    dealsError: null,
    productData: null,
    productLoading: false,
    productError: null,
    updateProductData: null,
    updateProductLoading: false,
    updateProductError: null,
    createProductData: null,
    createProductLoading: false,
    createProductError: null,
    deleteProductData: null,
    deleteProductLoading: false,
    deleteProductError: null,
    getProductByIdData: null,
    getProductByIdLoading: false,
    getProductByIdError: null,
    deletePhotoFromProductData: null,
    deletePhotoFromProductLoading: false,
    deletePhotoFromProductError: null,
    deleteSpecificationByIdData: null,
    deleteSpecificationByIdLoading: false,
    deleteSpecificationByIdError: null
}


const productSlice = createSlice({
    name: 'product',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(createSpecification.rejected, (state, action) => {
                
                state.createSpecificationError = action.error.message;
                state.createSpecificationLoading = false;
            })
            .addCase(getProductByCategory.fulfilled, (state, action) => {
                state.getProductByCategoryData = action.payload;
                state.getProductByCategoryLoading = false;
            })
            .addCase(getAllProductsByCategoryId.fulfilled, (state, action) => {
                state.getAllProductsByCategoryIdData = action.payload;
                state.getAllProductsByCategoryIdLoading = false;
            })
            .addCase(createSpecification.fulfilled, (state, action) => {
                state.createSpecificationData = action.payload;
                state.createSpecificationLoading = false;
            })
            .addCase(createSpecification.pending, (state) => {
                state.createSpecificationLoading = true;
                state.createSpecificationError = null;
            })
            .addCase(deleteSpecificationById.rejected, (state, action) => {
                state.deleteSpecificationByIdError = action.error.message;
                state.deleteSpecificationByIdLoading = false;
            })
            .addCase(deleteSpecificationById.fulfilled, (state, action) => {
                state.deleteSpecificationByIdData = action.payload;
                state.deleteSpecificationByIdLoading = false;
            })
            .addCase(deleteSpecificationById.pending, (state) => {
                state.deleteSpecificationByIdLoading = true;
                state.deleteSpecificationByIdError = null;
            })
            .addCase(setDealsOfTheWeek.rejected, (state, action) => {
                state.dealsError = action.error.message;
                state.dealsLoading = false;
            })
            .addCase(setDealsOfTheWeek.fulfilled, (state, action) => {
                state.dealsData = action.payload;
                state.dealsLoading = false;
            })
            .addCase(setDealsOfTheWeek.pending, (state) => {
                state.dealsLoading = true;
                state.dealsError = null;
            })
            .addCase(updateProduct.rejected, (state, action) => {
                state.updateProductError = action.error.message;
                state.updateProductLoading = false;
            })
            .addCase(updateProduct.fulfilled, (state, action) => {
                state.updateProductData = action.payload;
                state.updateProductLoading = false;
            })
            .addCase(updateProduct.pending, (state) => {
                state.updateProductLoading = true;
                state.updateProductError = null;
            })
            .addCase(deletePhotoFromProduct.rejected, (state, action) => {
                state.deletePhotoFromProductError = action.error.message;
                state.deletePhotoFromProductLoading = false;
            })
            .addCase(deletePhotoFromProduct.fulfilled, (state, action) => {
                state.deletePhotoFromProductData = action.payload;
                state.deletePhotoFromProductLoading = false;
            })
            .addCase(deletePhotoFromProduct.pending, (state) => {
                state.deletePhotoFromProductLoading = true;
                state.deletePhotoFromProductError = null;
            })
            .addCase(getProductById.rejected, (state, action) => {
                state.getProductByIdError = action.error.message;
                state.getProductByIdLoading = false;
            })
            .addCase(getProductById.fulfilled, (state, action) => {
                state.getProductByIdData = action.payload;
                state.getProductByIdLoading = false;
            })
            .addCase(getProductById.pending, (state) => {
                state.getProductByIdLoading = true;
                state.getProductByIdError = null;
            })
            .addCase(deleteProduct.rejected, (state, action) => {
                state.deleteProductError = action.error.message;
                state.deleteProductLoading = false;
            })
            .addCase(deleteProduct.fulfilled, (state, action) => {
                state.deleteProductData = action.payload;
                state.deleteProductLoading = false;
            })
            .addCase(deleteProduct.pending, (state) => {
                state.deleteProductLoading = true;
                state.deleteProductError = null;
            })
            .addCase(getAllProduct.rejected, (state, action) => {
                state.productError = action.error.message;
                state.productLoading = false;
            })
            .addCase(getAllProduct.fulfilled, (state, action) => {
                state.productData = action.payload;
                state.productLoading = false;
            })
            .addCase(getAllProduct.pending, (state) => {
                state.productLoading = true;
                state.productError = null;
            })
            .addCase(createProduct.rejected, (state, action) => {
                state.createProductError = action.error.message;
                state.createProductLoading = false;
            })
            .addCase(createProduct.fulfilled, (state, action) => {
                state.createProductData = action.payload;
                state.createProductLoading = false;
            })
            .addCase(createProduct.pending, (state) => {
                state.createProductLoading = true;
                state.createProductError = null;
            })
    }
})

export default productSlice.reducer;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";


export const getAllDiscounts = createAsyncThunk('discounts/getAllDiscounts', async ({ page = 1, limit = 25, searchTerm = '' }) => {
    try {
        const response = await axiosInstance.get(`/discount/getAllDiscount?page=${page}&limit=${limit}&search=${searchTerm}`);

        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})

export const deleteDiscount = createAsyncThunk('discounts/deleteDiscount', async (id) => {
    try {
        const response = await axiosInstance.delete(`/discount/deleteDiscount/${id}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})

export const createDiscount = createAsyncThunk('discounts/createDiscount', async (data) => {
    try {
        const response = await axiosInstance.post('/discount/createDiscount', data);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})


const initialState = {
    createDiscountData: null,
    createDiscountLoading: false,
    createDiscountError: null,
    discountData: null,
    discountLoading: false,
    discountError: null,
    deleteDiscountData: null,
    deleteDiscountLoading: false,
    deleteDiscountError: null
}

const discountSlice = createSlice({
    name: "discount",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createDiscount.rejected, (state, action) => {
                state.createDiscountError = action.error.message;
                state.createDiscountLoading = false;
            })
            .addCase(createDiscount.fulfilled, (state, action) => {
                state.createDiscountData = action.payload;
                state.createDiscountLoading = false;
            })
            .addCase(createDiscount.pending, (state) => {
                state.createDiscountLoading = true;
                state.createDiscountError = null;
            })
            .addCase(deleteDiscount.rejected, (state, action) => {
                state.deleteDiscountError = action.error.message;
                state.deleteDiscountError = null;
            })
            .addCase(deleteDiscount.fulfilled, (state, action) => {
                state.deleteDiscountData = action.payload;
                state.deleteDiscountLoading = false;
            })
            .addCase(deleteDiscount.pending, (state) => {
                state.deleteDiscountLoading = true;
                state.deleteDiscountError = null;
            })
            .addCase(getAllDiscounts.rejected, (state, action) => {
                state.discountError = action.error.message;
                state.discountLoading = false;
            })
            .addCase(getAllDiscounts.fulfilled, (state, action) => {
                state.discountData = action.payload;
                state.discountLoading = false;
            })
            .addCase(getAllDiscounts.pending, (state) => {
                state.discountLoading = true;
                state.discountError = null;
            })
    }
})

export default discountSlice.reducer;
import React, { useEffect, useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux';
import { MdDeleteForever } from "react-icons/md";
import {  CreateDiscountModal, Pagination } from '../../../components'
import { deleteDiscount, getAllDiscounts } from '../../../store/features/discountSlice';
import { formatDateString } from '../../../utils/time';
const Cupons = () => {
    const dispatch = useDispatch();
    const discountData = useSelector((state) => state.discount.discountData) || [];
    const deleteDiscountLoading = useSelector((state) => state.discount.deleteDiscountLoading);
    const createDiscountLoading = useSelector((state) => state.discount.createDiscountLoading);
    const [isOpen, setIsOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(25);


    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    
    const filterDiscountData = discountData?.data?.filter((item) => {
        const name = item.code && item.code.toLowerCase().includes(searchTerm.toLowerCase());
        return name;
    });

    const indexOfLastItem = currentPage * 25;
    const indexOfFirstItem = indexOfLastItem - 25;
    const currentItems = filterDiscountData?.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(discountData?.total / itemsPerPage);
    const openModal = () => {
        setIsOpen(!isOpen)
    }

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handelDeleteDiscount = (id) => {
        dispatch(deleteDiscount(id))
    }
    useEffect(() => {
        dispatch(getAllDiscounts({ page: currentPage, limit: 25, searchTerm }))
    }, [deleteDiscountLoading, createDiscountLoading,currentPage,searchTerm])

    return (
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
   <div className="flex flex-col sm:flex-row justify-between space-y-2 sm:space-y-0 pb-3">
   <div className="relative mb-4 sm:mb-10 w-full sm:w-auto">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 ">
                    <FiSearch className="w-4 h-4 text-gray-800 dark:text-gray-400" />
                </div>
                <input
                    type="text"
                    id="table-search"
                    className="block p-2 pl-10 text-sm text-gray-900 border border-black rounded-lg w-full sm:w-80 bg-white focus:ring-blue-500 focus:border-blue-500 dark:border-dark dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Kërko këtu"
                    value={searchTerm}
                    onChange={handleSearch}
                />
            </div>
            <div className="w-full sm:w-auto">
                    <button className="w-full sm:w-auto bg-[#1A97F5] text-white font-bold py-2 px-4 rounded" onClick={openModal}>
                    Shto Kuponin
                </button>
            </div>
        </div>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg" style={{ borderRadius: 1 }}>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700  text-white uppercase bg-[#1A97F5] dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-4 py-4">
                            Emri i kuponit
                        </th>
                        <th scope="col" className="px-4 py-4">
                            Perqindja
                        </th>
                        <th scope="col" className="px-4 py-4">
                            Data e aktivizimit
                        </th>
                        <th>
                            Data e skadimit
                        </th>
                        <th scope="col" className="px-4 py-4">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {discountData?.cupons?.map((item) => (
                        <tr
                            key={item.id}
                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                        >
                            <td className="px-4 py-4">{item.code}</td>
                            <td className="px-4 py-4">{item.amount} %</td>
                            <td className="px-4 py-4">{formatDateString(item.valid_from)}</td>
                            <td className="px-4 py-4">{formatDateString(item.valid_until)}</td>
                            <td className="px-4 py-4 flex space-x-4">
                                <button onClick={() => { handelDeleteDiscount(item.id)}}>
                                    <MdDeleteForever />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
        </div>
        {isOpen && <CreateDiscountModal openModal={openModal}/>}
    </div>
    )
}

export default Cupons

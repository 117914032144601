import { IoIosCloseCircle } from "react-icons/io";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllCategory } from "../../../store/features/categorySlice";
import { createManufacter } from "../../../store/features/manufacterSlice";

const CreateManufacterModal = ({ openModal }) => {
  const dispatch = useDispatch();
  const category =
    useSelector((state) => state.category.getAllCategoryData) || [];
  const [formData, setFormData] = useState({
    name: "",
    categoryId: "",
  });
  const onSubmit = (e) => {
    e.preventDefault();
    if (!formData.name && formData.categoryId)
      return toast.error("Mbushë të dhënat");
    const data = {
      name: formData.name,
      category_id: formData.categoryId,
    };

    dispatch(createManufacter(data))
      .then(() => {
        toast.success("Emri i prodhuesit u shtua me sukses");
      })
      .catch(() => toast.error("Diqka shkoi keq!"));
    setFormData({
      ...formData,
      name: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    dispatch(getAllCategory());
  }, [dispatch]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center w-15">
      <div className="bg-gray-900 bg-opacity-40 absolute inset-0"></div>
      <div
        className="relative bg-white rounded-lg shadow-lg"
        style={{ width: "420px" }}
      >
        <div className="p-6">
          <div className="flex items-center justify-between">
            <h3 className="mb-1 text-xl font-normal text-center text-gray-500 dark:text-gray-400">
              Shto Brendin
            </h3>
            <button onClick={openModal}>
              <IoIosCloseCircle />
            </button>
          </div>
          <form autoComplete="off" onSubmit={onSubmit}>
            <div>
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Emri
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div className="mt-4">
              <label
                htmlFor="categoryId"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Kategoria
              </label>
              <select
                name="categoryId"
                id="categoryId"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                value={formData.categoryId}
                onChange={handleChange}
              >
                <option value="">Zgjidh kategorinë</option>
                {category?.map((cat) => (
                  <option key={cat.id} value={cat.id}>
                    {cat.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex items-center justify-center mt-5">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
              >
                Shto Prodhuesit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateManufacterModal;

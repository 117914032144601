import { IoIosCloseCircle } from "react-icons/io";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../../store/features/categorySlice";

import { toast } from "react-toastify";
import { createDiscount } from "../../../store/features/discountSlice";
import Select from "react-select";
import { getAllProductsByCategoryId, getProductByCategory } from "../../../store/features/productSlice";

const CreateDiscountModal =  ({ openModal })=> {
    const dispatch = useDispatch();
    const [category_id, setCategory_id] = useState("");
    const [categorySlug, setCategorySlug] = useState("");
    const [product_id, setProduct_id] = useState("");
    const [productSlug, setProductSlug] = useState("");
    const categoryData = useSelector((state) => state.category.categoryData) || [];
    const productData = useSelector((state) => state.product.getAllProductsByCategoryIdData) || [];
    const [searchTerm, setSearchTerm] = useState('');

    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        code: '',
        amount: '',
        validForm: '',
        validUntil: ''
    });
    useEffect(() => {
        dispatch(getCategory({ all: true }))
        if (categorySlug) {
            dispatch(getAllProductsByCategoryId({ slug:categorySlug }));
        }
    }, [dispatch, categorySlug]);
    

    const onSubmit = (e) => {
        e.preventDefault();

        const  data = {  code: formData.code, amount: formData.amount, valid_from: formData.validForm, valid_until: formData.validUntil,category_id: category_id, product_id: product_id }
        dispatch(createDiscount(data)).unwrap()
                .then(() =>{ 
                    toast.success("Kuponi u shtua me sukses")
                    setFormData({
                        code: '',
                        amount: '',
                        validForm: '',
                        validUntil: '',
                    
                    })
                    setCategory_id('');
                    setProduct_id('');
                })
                .catch((error) => {
                    setError(error)
                    toast.error("Diqka shkoi keq!")
                })
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    console.log("productData",productData)
    console.log("categorySlug",categorySlug)
    console.log("category_id",category_id)
    console.log("product_id",product_id)


    const optionsProduct = productData?.products?.map((item) => ({
        value: JSON.stringify(item),
        label: `${item.name}`,
      }));

      const handleOnChangeProduct = (e) => {
        const { description, id } = JSON.parse(e.value);
        setProduct_id(id);
        setProductSlug(description);
      };
 

     const handleOnChangeCat = (e) => {
        const { description, id } = JSON.parse(e.value);
        setCategory_id(id);
        setCategorySlug(description);
      };
      const optionsCat = categoryData?.categories?.map((item) => ({
        value: JSON.stringify(item),
        label: `${item.name}`,
      }));
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-15">
        <div className="bg-gray-900 bg-opacity-40 absolute inset-0"></div>
        <div
            className="relative bg-white rounded-lg shadow-lg"
            style={{ width: "420px" }}
        >
            <div className="p-6 ">
                <div className="flex items-center justify-between">
                    <h3 className="mb-1 text-xl font-normal text-center text-gray-500 dark:text-gray-400">
                        Shto Kuponin
                    </h3>
                    <button onClick={openModal}>
                        <IoIosCloseCircle />
                    </button>
                </div>
                <p className="text-red-500 text-center">{error && error.message }</p>
                <form autoComplete="off" onSubmit={onSubmit}>
                    <div>
                        <label
                            htmlFor="code"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                            Emri
                        </label>
                        <input
                            type="text"
                            name="code"
                            id="code"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            placeholder="Emri i kuponit"
                            value={formData.code}
                            onChange={handleChange}
                        />

                    </div>
                    <div>
                        <label
                            htmlFor="amount"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                            Perqindja
                        </label>
                        <input
                            type="number"
                            name="amount"
                            id="amount"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            placeholder="Emri i kuponit"
                            value={formData.amount}
                            onChange={handleChange}
                        />
                    </div>
                        <div>
                            <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900">Kategoria</label>
                            <Select
                                name="category"
                                id="category"
                                options={optionsCat}
                                onChange={handleOnChangeCat}
                                classNamePrefix="react-select"
                                placeholder="Zgjidh njerin nga opsione"
                            />
                        </div>
                        <div>
                            <label htmlFor="product" className="block mb-2 text-sm font-medium text-gray-900">Produkti</label>
                            <Select
                                name="product"
                                id="product"
                                options={optionsProduct}
                                onChange={handleOnChangeProduct}
                                classNamePrefix="react-select"
                                placeholder="Zgjidh njerin nga opsione"
                            />
                        </div>
                    <div>
                        <label
                            htmlFor="validForm"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                            Data e Aktivizimit
                        </label>
                        <input
                            type="date"
                            name="validForm"
                            id="validForm"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            placeholder="Data e Aktivizimit"
                            value={formData.validForm}
                            onChange={handleChange}
                        />
                    </div>

                    <div>
                        <label
                            htmlFor="validUntil"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                            Data e Skadimit
                        </label>
                        <input
                            type="date"
                            name="validUntil"
                            id="validUntil"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            placeholder="Data e skadimit"
                            value={formData.validUntil}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="flex items-center justify-center mt-5">
                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
                        >
                            Shto Kuponin
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    )
}

export default CreateDiscountModal

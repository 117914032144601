import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";

export const getOrders = createAsyncThunk('order/getOrders', async ({ page = 1, limit = 25, searchTerm = '' }) => {
    try {
        const response = await axiosInstance.get(`/order/getAllOrder?page=${page}&limit=${limit}&search=${searchTerm}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
});

export const updateStatusOrder = createAsyncThunk('order/updateStatusOrder', async ({id, data}) => {
    try {
        const response = await axiosInstance.put(`/order/updateStatus/${id}`, data);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
});

export const createOrderByAdmin = createAsyncThunk('order/createOrderByAdmin', async (data) => {
    try {
        const response = await axiosInstance.post("/order/createOrderByAdmin", data);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
});

export const getDetailOrder = createAsyncThunk('order/getOrderDetails', async (id) => {
    try {
        const response = await axiosInstance.get(`/order/getDetailsOrder/${id}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
});

const initialState = {
    createOrderByAdminData: null,
    createOrderByAdminLoading: false,
    createOrderByAdminError: null,
    orderData: null,
    orderLoading: false,
    orderError: null,
    updateStatusData: null,
    updateStatusLoading: false,
    updateStatusError: null,
    detailsOrderData: null,
    detailsOrderLoading: false,
    detailsOrderError: null
};

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDetailOrder.pending, (state) => {
                state.detailsOrderLoading = true;
                state.detailsOrderError = null;
            })
            .addCase(getDetailOrder.fulfilled, (state, action) => {
                state.detailsOrderData = action.payload;
                state.detailsOrderLoading = false;
            })
            .addCase(getDetailOrder.rejected, (state, action) => {
                state.detailsOrderError = action.error.message;
                state.detailsOrderLoading = false;
            })
            .addCase(createOrderByAdmin.pending, (state) => {
                state.createOrderByAdminLoading = true;
                state.createOrderByAdminError = null;
            })
            .addCase(createOrderByAdmin.fulfilled, (state, action) => {
                state.createOrderByAdminData = action.payload;
                state.createOrderByAdminLoading = false;
            })
            .addCase(createOrderByAdmin.rejected, (state, action) => {
                state.createOrderByAdminError = action.error.message;
                state.createOrderByAdminLoading = false;
            })
            .addCase(updateStatusOrder.pending, (state) => {
                state.updateStatusLoading = true;
                state.updateStatusError = null;
            })
            .addCase(updateStatusOrder.fulfilled, (state, action) => {
                state.updateStatusData = action.payload;
                state.updateStatusLoading = false;
            })
            .addCase(updateStatusOrder.rejected, (state, action) => {
                state.updateStatusError = action.error.message;
                state.updateStatusLoading = false;
            })
            .addCase(getOrders.pending, (state) => {
                state.orderLoading = true;
                state.orderError = null;
            })
            .addCase(getOrders.fulfilled, (state, action) => {
                state.orderData = action.payload;
                state.orderLoading = false;
            })
            .addCase(getOrders.rejected, (state, action) => {
                state.orderError = action.error.message;
                state.orderLoading = false;
            });
    }
});

export default orderSlice.reducer;

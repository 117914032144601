import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";

export const getAllProductUser = createAsyncThunk('productUser/getProductUser', async () => {
    try {
        const response = await axiosInstance.get('/product/getUserProduct');
        return response.data
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})

export const createProductUser = createAsyncThunk('productUser/createProductUser', async (data) => {
    try {
        const response = await axiosInstance.post('/product/createProductUser', data);
        return response.data
    } catch (error) {
        throw new Error(error.response.data.message);
    }
}) 

const initialState = {
    prodcutUserData: null,
    prodcutUserLoading: false,
    prodcutUserError: null,
    createProductUserData: null,
    createProductUserLoading: false,
    createProductUserError: null
}


const productUserSlice = createSlice({
    name: 'productUser',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAllProductUser.rejected, (state, action) => {
                state.prodcutUserError = action.error.message;
                state.prodcutUserLoading = false;
            })
            .addCase(getAllProductUser.fulfilled, (state, action) => {
                state.prodcutUserData = action.payload;
                state.prodcutUserLoading = false;
            })
            .addCase(getAllProductUser.pending, (state) => {
                state.prodcutUserLoading = true;
                state.prodcutUserError = null;
            })
            .addCase(createProductUser.rejected, (state, action) => {
                state.createProductUserError = action.error.message;
                state.createProductUserLoading = false;
            })
            .addCase(createProductUser.fulfilled, (state, action) =>{
                state.createProductUserData = action.payload;
                state.createProductUserLoading = false;
            })
            .addCase(createProductUser.pending, (state) => {
                state.createProductUserLoading = true;
                state.createProductUserError = null;
            })
    }
})

export default productUserSlice.reducer;
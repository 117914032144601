import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";

export const userCount = createAsyncThunk('statistic/userCount', async () => {
    try {
        const response = await axiosInstance.get('/statistic/userCount');
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})

export const categoryCount = createAsyncThunk('statistic/categoryCount', async () => {
    try {
        const response = await axiosInstance.get('/statistic/categoryandsubcategoryCount');
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})


const initialState = {
    categoryCountData: null,
    categoryCountLoading: false,
    categoryCountError: null,
    userCountData: null,
    userCountLoading: false,
    userCountError: null
}

const statisticSlice = createSlice({
    name: "statistic",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(categoryCount.rejected, (state, action) => {
                state.categoryCountError = action.error.message;
                state.categoryCountLoading = false;
            })
            .addCase(categoryCount.fulfilled, (state, action) => {
                state.categoryCountData = action.payload;
                state.categoryCountLoading = false;
            })
            .addCase(categoryCount.pending, (state) => {
                state.categoryCountLoading = true;
                state.categoryCountError = null;
            })
            .addCase(userCount.rejected, (state, action) => {
                state.userCountError = action.error.message;
                state.userCountLoading = false;
            })
            .addCase(userCount.fulfilled, (state, action) => {
                state.userCountData = action.payload;
                state.userCountLoading = false;
            })
            .addCase(userCount.pending, (state) => {
                state.userCountLoading = true;
                state.userCountError = null;
            })
    }
})
export default statisticSlice.reducer;
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userCount } from '../../store/features/statisticSlice';
import { Bar } from 'react-chartjs-2';
import { useState } from 'react';

const UserCount = () => {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.statistic.userCountData) || {};
    const [data, setData] = useState(userData);
    
    const chartData = {
      labels: ['Të gjith pëdoruesit', 'Muaji i fundit', '3 muajt e fundit', ' 6 muajt e fundit', 'Vitin e kaluar'],
      datasets: [
        {
          label: 'Perdoruesit ',
          data: [userData.allUsers, userData.lastMonth, userData.last3Months, userData.last6Months, data.lastYear],
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
        }
      ]
    };
  
  
    useEffect(() => {
      dispatch(userCount())
    }, [])
  
  return (
    <div className="App">
      <div style={{ width: '600px', margin: '0 auto' }}>
        <Bar data={chartData} />
      </div>
    </div>
  )
}

export default UserCount

import React, { useState } from "react";
import mainLogoLogin from "../../assets/mainLogoLogin.jpg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { loginUser } from "../../store/features/authSlice";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    const credentials = { email, password };

    dispatch(loginUser(credentials))
      .unwrap()
      .then((response) => {
        localStorage.setItem("token", response.token);
        // Decode the token to get user role
        const decodedToken = jwtDecode(response.token);
        const userRole = decodedToken?.role;

        // Navigate based on user role
        if (userRole === "user") {
          navigate("/user-products");
        } else {
          navigate("/");
        }
      })
      .catch((error) => {
        console.error("Login failed:", error.message);
        setError(error.message);
      });
  };

  return (
    <section className="h-screen flex items-center justify-center">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-center">
        <div className="hidden md:block md:w-1/2">
          <img
            src={mainLogoLogin}
            className="w-full h-full object-cover"
            alt="main login logo"
          />
        </div>
        <div className="w-full md:w-1/2 flex flex-col items-center justify-center">
          <div className="w-full max-w-md p-4">
            <form className="mt-4" onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  className="block text-gray-600 text-sm font-semibold mb-2"
                  htmlFor="email"
                >
                  Email-i
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full p-3 border rounded-md"
                  placeholder="Vendos Emailin"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-600 text-sm font-semibold mb-2"
                  htmlFor="password"
                >
                  Fjalkalimin
                </label>
                <input
                  type="password"
                  id="password"
                  className="w-full p-3 border rounded-md"
                  placeholder="Vendos fjalkalimin"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {error && (
                <p className="text-center mb-3 text-red-500">{error}</p>
              )}
              <button
                type="submit"
                className="w-full bg-blue-500 text-white p-3 rounded-md hover:bg-blue-600"
              >
                Kyçu
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;

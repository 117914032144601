import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";

export const getAllUser = createAsyncThunk(
  "user/getAllUser",
  async ({ page = 1, limit = 25, searchTerm = "", all = false }) => {
    try {
      const allParam = all ? `&all=true` : "";
      const response = await axiosInstance.get(
        `/auth/getUser?page=${page}&limit=${limit}&search=${searchTerm}${allParam}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getUserById = createAsyncThunk("user/getUserById", async (id) => {
  try {
    const response = await axiosInstance.get(`/auth/getUser/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const createUser = createAsyncThunk("user/createUser", async (data) => {
  try {
    const response = await axiosInstance.post("/auth/register", data);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async ({ id, formData }) => {
    try {
      const response = await axiosInstance.put(`/auth/update/${id}`, formData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const deleteUser = createAsyncThunk("user/deleteUser", async (id) => {
  try {
    const response = await axiosInstance.delete(`/auth/delete/${id}`, id);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const getUserByJWT = createAsyncThunk("user/getUserByJWT", async () => {
  try {
    const response = await axiosInstance.get("/auth/getUserByJwt");
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

const initialState = {
  allUserData: null,
  allUserLoading: false,
  allUserError: null,
  getUserByIdData: null,
  getUserByIdLoading: false,
  getUserByIdError: null,
  createUserData: null,
  createUserLoading: false,
  createUserError: null,
  updateUserData: null,
  updateUserLoading: false,
  updateUserError: null,
  deleteUserData: null,
  deleteUserLoading: false,
  deleteUserError: null,
  getUserByJWTData: null,
  getUserByJWTLoading: false,
  getUserByJWTError: null,
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserByJWT.rejected, (state, action) => {
        state.getUserByJWTError = action.error.message;
        state.getUserByJWTLoading = false;
      })
      .addCase(getUserByJWT.fulfilled, (state, action) => {
        state.getUserByJWTData = action.payload;
        state.getUserByJWTLoading = false;
      })
      .addCase(getUserByJWT.pending, (state) => {
        state.getUserByJWTLoading = true;
        state.getUserByJWTError = null;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.deleteUserError = action.error.message;
        state.deleteUserLoading = false;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.deleteUserData = action.payload;
        state.deleteUserLoading = false;
      })
      .addCase(deleteUser.pending, (state) => {
        state.deleteUserLoading = true;
        state.deleteUserError = null;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.updateUserError = action.error.message;
        state.updateUserLoading = false;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.updateUserData = action.payload;
        state.updateUserLoading = false;
      })
      .addCase(updateUser.pending, (state) => {
        state.updateUserLoading = true;
        state.updateUserError = null;
      })
      .addCase(getAllUser.rejected, (state, action) => {
        state.allUserError = action.error.message;
        state.allUserLoading = false;
      })
      .addCase(getAllUser.fulfilled, (state, action) => {
        state.allUserData = action.payload;
        state.allUserLoading = false;
      })
      .addCase(getAllUser.pending, (state) => {
        state.allUserLoading = true;
        state.allUserError = null;
      })
      .addCase(getUserById.rejected, (state, action) => {
        state.getUserByIdError = action.error.message;
        state.getUserByIdLoading = false;
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.getUserByIdData = action.payload;
        state.getUserByIdLoading = false;
      })
      .addCase(getUserById.pending, (state) => {
        state.getUserByIdLoading = true;
        state.getUserByIdError = null;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.createUserError = action.error.message;
        state.createUserLoading = false;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.createUserData = action.payload;
        state.createUserLoading = false;
      })
      .addCase(createUser.pending, (state) => {
        state.createUserLoading = true;
        state.createUserError = null;
      });
  },
});

export default userSlice.reducer;

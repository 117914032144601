import { IoIosCloseCircle } from "react-icons/io";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { createItemSubCategory } from "../../../store/features/itemSubCategorySlice";

const CreateItemSubCategoryModal = ({ openModal, id }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: '',
        description: ''
    });

    const onSubmit = (e) => { 
        e.preventDefault();
        const data = {
            name: formData.name,
            description: formData.description,
            itemsubcategory_id: id
        }

        dispatch(createItemSubCategory(data)).unwrap()
                .then(() => {
                    toast.success(
                        "Nënkategoria u shtua me sukses!"
                    )
                })
                .catch(() => { 
                    toast.error("Diqka shkoij keqë!")
                })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-15">
        <div className="bg-gray-900 bg-opacity-40 absolute inset-0"></div>
        <div
            className="relative bg-white rounded-lg shadow-lg"
            style={{ width: "420px" }}
        >
            <div className="p-6">
                <div className="flex items-center justify-between">
                    <h3 className="mb-1 text-xl font-normal text-center text-gray-500 dark:text-gray-400">
                        Shto nënkategorinë
                    </h3>
                    <button onClick={openModal}>
                        <IoIosCloseCircle />
                    </button>
                </div>
                <form autoComplete="off" onSubmit={onSubmit}>
                    <div>
                        <label
                            htmlFor="name"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                            Emri
                        </label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            placeholder="Name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>

                    <div>
                        <label
                            htmlFor="description"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                            Përshkrimi
                        </label>
                        <input
                            type="text"
                            name="description"
                            id="description"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            placeholder="Description"
                            value={formData.description}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="flex items-center justify-center mt-5">
                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
                        >
                            Shto Nënkategorinë
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    )
}

export default CreateItemSubCategoryModal



